<template>
  <!-- <div class="app app-body flex-row align-items-center"> -->
  <div class="app flex-row align-items-center">
    <div id="spinner" class="justify-content-center align-items-center"
        :style="{ height: spinnerHeight + 'px', 
          width: spinnerWidth + 'px' }" 
        v-if="spinnerVisible">
      <b-spinner id="spinner-spin" label="Loading...">
      </b-spinner>
    </div>
    <!-- <app-procesando 
      :procesando="procesando">
    </app-procesando> -->
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="p-4 login-card select-empresa">
              <b-card-body>
                <b-form>
                  <h1>Iniciar Sesión</h1>
                  <p class="text-muted">Seleccione la empresa</p>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-organization"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select 
                      class="form-control custom-select"
                      v-model="empresaNombre" 
                      :options="empresasList"
                      :state="empresaValida"
                      @blur.native="empresaActualizada">
                      <template slot="first">
                        <option :value="null" :disabled="true">Seleccione una Empresa</option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="feedback">
                      Seleccione la empresa
                    </b-form-invalid-feedback>
                  </b-input-group>
                  <b-row>
                    <b-col cols="12">
                      <b-button variant="primary" 
                        class="px-4"
                        @click.prevent="seleccionarEmpresa">Seleccionar</b-button>
                      <b-button variant="primary" 
                        class="px-4"
                        @click.prevent="goBack">Volver</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>
  </div>
</template>

<script>
// import Procesando from '../shared/spinnerProcesando.vue';
import Empresa from '../shared/abmSelect.vue';

export default {
  name: 'Empresas',
  components: {
    // appProcesando: Procesando,
    appEmpresa: Empresa
  },

  data () {
    return {
      empresa: null,
      empresaNombre: null,
      
      empresas: null,
      empresasList: [],
      empresaValida: null,
    }
  },
  computed: {
    spinnerVisible: {
      get: function () {
        return this.$store.getters['spinnerProcesando/procesando'];
      },
      set: function (newValue) {
      }
    },
    spinnerHeight: {
      get: function () {
        return this.$store.getters['spinnerProcesando/height'];
      },
      set: function (newValue) {
      }
    },
    spinnerWidth: {
      get: function () {
        return this.$store.getters['spinnerProcesando/width'];
      },
      set: function (newValue) {
      }
    },
  },
  created() {
    this.empresas = this.$store.getters['user/empresas'];
    this.empresasList = this.$store.getters['user/empresasList'];
  },
  methods: {
    seleccionarEmpresa (){
      if (this.empresa) {
        let params = {
          empresaId: this.empresa.id
        }
        this.showSpinner();
        this.$store.dispatch('user/loginEmpresa', params)
          .then(() => this.$router.push({ name: 'Home' }))
          .catch(error => this.showErrorMsj(error))
          .finally(() => this.hideSpinner());
      } else {
        this.empresaValida = false;
      }
    },
    empresaActualizada() {
      this.empresa = null;
      if (this.empresaNombre) {
        this.empresa = this.empresas.find( empresa => empresa.nombre.trim() == this.empresaNombre.trim());
      }
      this.empresaValida = null;
    },    
    goBack() {
      this.$router.push({ name: 'Login' });
    },    
  }
}
</script>

<style>
</style>